import React, { useEffect, useState } from "react";
// import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
// import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { prDownlaodThunk } from "../redux/adminPrsSlice";
import { useParams } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AdminPrsPreviewContent = () => {
  const { prDownload, loading } = useSelector((state) => state.adminPrs);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [
    error,
    // setError
  ] = useState(null);
  const dispatch = useDispatch();
  const id = useParams()?.id;
  const fetchDataAndGeneratePdf = async () => {
    const itemDetailsTable = [
      [
        {
          text: "Item Description",
          style: ["title", "default_font"],
        },
        {
          text: "Material Code",
          style: ["title", "default_font"],
        },
        {
          text: "UOM",
          style: ["title", "default_font"],
        },
        {
          text: "Quantity",
          style: ["title", "default_font"],
        },
        {
          text: "Frequency",
          style: ["title", "default_font"],
        },
        {
          text: "Purpose",
          style: ["title", "default_font"],
        },
      ],
      ...(prDownload?.details?.PrMaterials?.length
        ? prDownload?.details?.PrMaterials.map((material) => [
            {
              text: material?.MaterialMaster?.Description,
              style: ["default_font", "value_color"],
            },
            {
              text: material?.MaterialMaster?.MaterialCode,
              style: ["default_font", "value_color"],
            },
            {
              text: material?.uom,
              style: ["default_font", "value_color"],
            },
            {
              text: material?.quantity,
              style: ["default_font", "value_color"],
            },
            {
              text: material?.frequency,
              style: ["default_font", "value_color"],
            },
            {
              text: material?.purpose === "" ? "N/A" : material.purpose,
              style: ["default_font", "value_color"],
            },
          ])
        : []),
    ];
    let approvalList = [];

    approvalList = [
      [
        {
          text: `CREATED by ${prDownload?.details?.User?.first_name} ${prDownload?.details?.User?.last_name} (${prDownload?.details?.User?.contact_person_email})`,
          style: ["default_font", "header_font", { margin: [0, 0, 5, 0] }], // Added "underline_text" style
        },
        {
          ul: [
            {
              text: [
                { text: "PR Number: ", bold: true, style: ["default_font"] },
                `${prDownload?.details?.pr_number}`,
              ],
              style: ["default_font", "value_color"],
            },
            {
              text: [
                { text: "PR Amount: ", bold: true, style: ["default_font"] },
                `${prDownload?.details?.pr_amount}`,
              ],
              style: ["default_font", "value_color"],
            },
            {
              text: [
                { text: "Created At: ", bold: true, style: ["default_font"] },
                moment(prDownload?.details?.createdAt).format(
                  "DD-MMM-YYYY HH:mm:ss"
                ),
              ],
              style: ["default_font", "value_color"],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5, // Adjust position of the line
              x2: 515, // Line width
              y2: 5,
              lineWidth: 0.5,
              lineColor: "#818181", // Gray color
            },
          ],
        },
        { text: "\n" },
      ],

      ...(prDownload?.history?.WorkflowInstanceStepsMaterials
        ? prDownload?.history?.WorkflowInstanceStepsMaterials?.map(
            (material) => [
              {
                text: `${material?.action_performed?.toUpperCase()} by ${
                  material.User.first_name
                } ${material.User.last_name}  (${
                  material?.User?.contact_person_email
                })`,
                style: [
                  "default_font",
                  "header_font",
                  { margin: [0, 0, 5, 0] },
                ], // Added "underline_text" style
              },

              {
                ul: [
                  // Bold the "Created At" text
                  {
                    text: [
                      {
                        text: "Item Description: ",
                        bold: true,
                        style: ["title", "default_font"],
                      },
                      material?.MaterialMaster?.Description || "N/A",
                    ],
                    style: ["default_font", "value_color"],
                  },
                  {
                    text: [
                      {
                        text: "Approver Step:",
                        style: ["title", "default_font"],
                      },
                      material.step,
                    ],
                    style: ["default_font", "value_color"],
                  },
                  {
                    text: [
                      { text: "Reason: ", style: ["title", "default_font"] },
                      material?.reason?.replace(/<\/?[^>]+(>|$)/g, "") || "N/A",
                    ],
                    style: ["default_font", "value_color"],
                  },
                  {
                    text: [
                      { text: "Created at ", bold: true },
                      moment(material.createdAt).format("DD-MMM-YYYY HH:mm:ss"),
                    ],
                    style: ["default_font", "value_color"],
                  },
                  {
                    text: [
                      {
                        text: `Action Performed at `,
                        bold: true,
                      },
                      moment(material.updatedAt).format("DD-MMM-YYYY HH:mm:ss"),
                    ],
                    style: ["default_font", "value_color"],
                  },
                ],
              },
              {
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 5, // Adjust position of the line
                    x2: 515, // Line width
                    y2: 5,
                    lineWidth: 0.5,
                    lineColor: "#818181", // Gray color
                  },
                ],
              },
              { text: "\n" }, // Adds spacing between each approval
            ]
          )
        : []),
    ]; // Flatten the array to ensure proper list rendering

    const docDefinition = {
      content: [
        {
          text: "LILY WORLDWIDE PVT. LTD.",
          style: { fontSize: 18, alignment: "center" },
        },
        { text: "Purchase Requisitions Report", style: "header" },
        { text: "PR Details", style: "sub_header" },
        {
          table: {
            widths: [100, 150, 100, 150],
            body: [
              [
                { text: "PR Number:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.pr_number}`,
                  style: ["default_font", "value_color"],
                },
                { text: "PR Amount:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.pr_amount}`,
                  style: ["default_font", "value_color"],
                },
              ],
              [
                { text: "Expense Type:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.expense_type}`,
                  style: ["default_font", "value_color"],
                },
                { text: "Urgency Level:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.urgency_level?.toUpperCase()}`,
                  style: ["default_font", "value_color"],
                },
              ],
              [
                {
                  text: "Reason of Purchase:",
                  style: ["title", "default_font"],
                },
                {
                  text: prDownload?.details?.reason_of_purchase || "N/A",
                  style: ["default_font", "value_color"],
                },
                { text: "remarks:", style: ["title", "default_font"] },
                {
                  text: prDownload?.details?.remark || "N/A",
                  style: ["default_font", "value_color"],
                },
              ],
              [
                {
                  text: "Requisitioner Name:",
                  style: ["title", "default_font"],
                },
                {
                  text: `${prDownload?.details?.User?.first_name} ${prDownload?.details?.User?.last_name}`,
                  style: ["default_font", "value_color"],
                },
                {
                  text: "Requisitioner Email:",
                  style: ["title", "default_font"],
                },
                {
                  text:
                    prDownload?.details?.User?.contact_person_email || "N/A",
                  style: ["default_font", "value_color"],
                },
              ],
              [
                { text: "Department Name:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.Company?.company_name}`,
                  style: ["default_font", "value_color"],
                },
                {
                  text: "Parent Department:",
                  style: ["title", "default_font"],
                },
                {
                  text:
                    prDownload?.details?.Company?.Company?.company_name ||
                    "N/A",
                  style: ["default_font", "value_color"],
                },
              ],
              [
                { text: "Delivery Date:", style: ["title", "default_font"] },
                {
                  text: `${moment(
                    prDownload?.details?.desired_delivery_date
                  ).format("DD-MMM-YYYY")}`,
                  style: ["default_font", "value_color"],
                },
                { text: "Create Date:", style: ["title", "default_font"] },
                {
                  text: `${moment(prDownload?.details?.createdAt).format(
                    "DD-MMM-YYYY HH:mm:ss"
                  )}`,
                  style: ["default_font", "value_color"],
                },
              ],
              [
                { text: "Shipping Address:", style: ["title", "default_font"] },
                {
                  text: `${prDownload?.details?.shipping_address}`,
                  style: ["default_font", "value_color"],
                },
                "",
                "",
              ],
            ],
          },
        },
        { text: "Item Details", style: "sub_header" },
        {
          table: {
            widths: [180, 75, 30, 40, "*", 110],
            body: itemDetailsTable,
          },
        },
        { text: "History", style: "sub_header" },
        ...approvalList,
      ],
      footer: function (currentPage, pageCount) {
        return {
          columns: [
            {
              text: `Generated on: ${moment().format("DD-MMM-YYYY")}`,
              alignment: "left",
              margin: [40, 0],
            },
            {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: "right",
              margin: [0, 0, 40, 0],
            },
          ],
          fontSize: 9,
          margin: [0, 10, 0, 0],
        };
      },
      styles: {
        header: {
          alignment: "center",
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        sub_header: {
          fontSize: 14,
          bold: true,
          decoration: "underline",
          margin: [0, 10, 0, 10],
        },
        title: {
          bold: true,
        },
        default_font: {
          fontSize: 10,
        },
        value_color: {
          color: "#585656",
        },
        underline_text: {
          decoration: "underline", // This adds the underline
        },
        // content: {
        //   fontSize: 12,
        //   margin: [0, 0, 0, 5],
        // },
      },
    };

    // Generate PDF blob and create URL
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    });
  };

  useEffect(() => {
    if (prDownload?.details) {
      fetchDataAndGeneratePdf();
    }
    // eslint-disable-next-line
  }, [prDownload?.details]);

  useEffect(() => {
    dispatch(prDownlaodThunk(id));
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {error && <p>{error}</p>}
      {loading ? (
        "Loading......."
      ) : (
        <div>
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "600px", border: "none" }}
            title="PDF Preview"
          />
        </div>
      )}
    </div>
  );
};

export default AdminPrsPreviewContent;
