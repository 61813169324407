import React, { useState } from "react";

import { Box, CardContent, Grid, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { VendorCardTitle } from "../../adminOrders/components/adminOrdersViewContent";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { useDispatch } from "react-redux";
import { getAddVendorTerms } from "../redux/vendorTermsAndConditionSlice";

const VendorTermsAndConditionContent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    unregister,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSave = (data) => {
    console.log("datatata", data);
    dispatch(getAddVendorTerms(data));
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Fill all details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={6}>
                <VendorCardLabel>
                  URL<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder={`Enter  URL`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="url"
                  {...register("url", {
                    required: `Please enter a URL`,
                    pattern: {
                      value:
                        /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                      message: "Please enter a valid URL (http://example.com)",
                    },
                  })}
                  error={Boolean(errors?.url)}
                  helperText={errors?.url?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel> Version </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  type="text"
                  placeholder={`Enter Version`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="version"
                  {...register("version")}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add Terms&Condition
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default VendorTermsAndConditionContent;
