import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const pendingList = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.pending_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const approvedList = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.approved_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const returnedList = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.returned_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};
export const getAllVendorForAdmin = (
  status,
  register,
  page,
  per_page,
  search,
  filter
) => {
  return apiClient.get(
    `${
      apiClient.Urls.getALLVendorAdmin
    }?status=${status}&register=${register}&page=${page}&per_page=${per_page}&filter=${filter}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const upload_reg = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.vendor_reg_upload}`,
    payload,
    true,
    "file"
  );
};

export const sendToSAP = (payload) => {
  return apiClient.post(`${apiClient.Urls.pushTosap}`, payload, true);
};

export const fetchVendorCodewithCompany = (payload) => {
  const role = LocalStorage.getItem("tata_login_user")?.roles[0];
  return apiClient.get(
    role === "officer"
      ? `${apiClient.Urls.fetchVendorcodeWithCompanyNameForOfficer}/${payload}`
      : `${apiClient.Urls.fetchVendorcodeWithCompanyName}/${payload}`,
    "",
    true
  );
};

export const vendorListCount = (payload) => {
  const role = LocalStorage.getItem("tata_login_user")?.roles[0];
  return apiClient.get(
    role === "officer"
      ? `${apiClient.Urls.officer_vendor_count}`
      : `${apiClient.Urls.admin_vendor_count}`,
    "",
    true
  );
};
export const DownloadAprovedVendor = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.downloadApprovedVendorExcel}`,
    "",
    true,
    "file"
  );
};
export const initiatedVendorDataAPI = (page, per_page, search) => {
  const role = LocalStorage.getItem("tata_login_user")?.roles[0];
  return apiClient.get(
    role === "officer"
      ? `${
          apiClient.Urls.OfficerInitiatedVendorData
        }?page=${page}&per_page=${per_page}&search=${search || ""}`
      : `${
          apiClient.Urls.initiatedVendorData
        }?page=${page}&per_page=${per_page}&search=${search || ""}`,
    "",
    true
  );
};
