/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Box,
  CardContent,
  Grid,
  TextField,
  Typography,
  //   FormControl,
  //   RadioGroup,
  //   FormControlLabel,
  //   Radio,
  //   Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import {
  Super,
  //   VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import GlobalAutocomplete from "../../../common/sharedComponents/AutoComplate";
import { history } from "../../../app/history";
import { createItem, getHsnList } from "../redux/itemSlice";
// import LocalStorage from "../../../utilities/localStorage";
import { useEffect } from "react";
// import GlobalAutocomplete from "../../../common/sharedComponents/AutoComplate";

function ItemContent() {
  const { hsnList } = useSelector((state) => state.item);
  const dispatch = useDispatch();
  //   const ClientId = LocalStorage.getItem("client")?.id;
  const [loading, setLoading] = useState(false);
  const [isHsnSelected, setIsHsnSelected] = useState(false);
  const [hsnValue, setHsnValue] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    // unregister,
  } = useForm();
  const hsnObj = watch("hsn");

  // Function to handle the form submission and save the company details
  const handleSave = (data) => {
    // Set the loading state to true to show the loading spinner
    setLoading(true);

    // Create an object with the item details to be passed to the API
    let item_details = {
      hsnSacCodeId: hsnObj.key,
      Description: data.Description,
      MaterialCode: data.MaterialCode,
      UOM: data.UOM,
    };

    // Dispatch the createItem action with the company_details object as the payload
    dispatch(createItem(item_details)).then((response) => {
      // If the response from the API is successful, set the loading state to false and navigate to the company page
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/item");
      }
      // If the response from the API is not successful, set the loading state to false
      else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (hsnValue === "") {
      setIsHsnSelected(false);
    }
  }, [hsnValue]);

  const handleHsnChange = (_, newValue) => {
    setHsnValue(newValue);
    if (newValue === "") {
      setIsHsnSelected(false);
    } else if (newValue.length >= 2) {
      setIsHsnSelected(true);
      setTimeout(() => {
        dispatch(getHsnList(newValue));
      }, 1000); // Delay of 1 second (1000 milliseconds)
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Item
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  HSN/SAC Code<Super>*</Super>
                </VendorCardLabel>
                <GlobalAutocomplete
                  name="hsn"
                  control={control}
                  options={hsnList?.flatMap((el) => {
                    return {
                      key: el.id,
                      value: el?.item,
                    };
                  })}
                  placeholder={`Enter at least 2 characters of HSN/SAC Code`}
                  rules={{ required: `HSN/SAC Code is required` }}
                  isMultiple={false}
                  onInputChange={(_, newValue) => {
                    handleHsnChange(_, newValue);
                  }}
                  setIsHsnSelected={setIsHsnSelected}
                  onSelect={() => {}}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              {isHsnSelected && (
                <>
                  <Grid item xs={6} md={6}>
                    <Typography className="label">Type</Typography>
                    <VendorCardLabel>
                      {hsnObj?.key
                        ? hsnList?.find((el) => el?.id === hsnObj?.key)?.type
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography className="label">
                      Category Description
                    </Typography>
                    <VendorCardLabel>
                      {hsnObj?.key
                        ? hsnList?.find((el) => el?.id === hsnObj?.key)
                            ?.categortDescription
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography className="label">
                      Sub-Category Description
                    </Typography>
                    <VendorCardLabel>
                      {hsnObj?.key
                        ? hsnList?.find((el) => el?.id === hsnObj?.key)
                            ?.subCategoryDescription
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography className="label">Item Description</Typography>
                    <VendorCardLabel>
                      {hsnObj?.key
                        ? hsnList?.find((el) => el?.id === hsnObj?.key)
                            ?.itemDescription
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                </>
              )}
            </Grid>

            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>
                  Item Description<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder={`Enter item description`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="Description"
                  {...register("Description", {
                    required: `Please enter item description`,
                  })}
                  error={Boolean(errors?.Description)}
                  helperText={errors?.Description?.message}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>
                  Item Code<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder={`Enter item code`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="MaterialCode"
                  {...register("MaterialCode", {
                    required: `Please enter item code`,
                  })}
                  error={Boolean(errors?.MaterialCode)}
                  helperText={errors?.MaterialCode?.message}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>
                  UOM<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder={`Enter item code`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="UOM"
                  {...register("UOM", {
                    required: `Please enter UOM`,
                  })}
                  error={Boolean(errors?.UOM)}
                  helperText={errors?.UOM?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add Item
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default ItemContent;
