import React, { useEffect } from "react";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import {
  Card,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { AddButton } from "../../bankDetails/components/bankAccountsContent";
import {
  CustomTableContainer,
  CustomTableHead,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAddVendorTermsList } from "../redux/vendorTermsAndConditionSlice";
import moment from "moment";

const VendorTermsAndConditionTableContent = ({
  search,
  clicked,
  setClicked,
}) => {
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [reload, setreload] = React.useState(false);

  const { getVendorTerms } = useSelector(
    (state) => state.vendorTermsAndCondition
  );
  const dispatch = useDispatch();
  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };
  const getvendorTermsList = () => {
    const payload = {
      page: page,
      per_page: per_page,
      search: search,
    };
    dispatch(getAddVendorTermsList(payload)).then((res) => {
      if (res?.payload?.success) {
        setClicked(false);
      }
    });
  };

  useEffect(() => {
    getvendorTermsList();
  }, []);
  useEffect(() => {
    if (clicked == true) {
      getvendorTermsList();
    }
  }, [clicked]);

  console.log("getVendorTerms", getVendorTerms);

  return (
    <div>
      <CustomTableContainer component={Card}>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell>
                <TableHeaderFont>Sr. No.</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont> Terms & Condition</TableHeaderFont>
              </TableCell>

              <TableCell>
                <TableHeaderFont>Version</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont> Date</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>

          <TableBody>
            {getVendorTerms?.data?.map((item, index) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>
                  {item.terms_and_condtions_url ? (
                    <a
                      href={item.terms_and_condtions_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.terms_and_condtions_url.length > 40
                        ? `${item.terms_and_condtions_url.slice(0, 30)}...`
                        : item.terms_and_condtions_url}
                    </a>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{item?.version}</TableCell>

                <TableCell>
                  {item.createdAt
                    ? moment(item.createdAt).format("DD MMM YY")
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomTableContainer>
      {/* table footer  */}
      <Grid
        container
        className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
        sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
      >
        <Grid className="d-flex align-items-center gap-2">
          <span>Rows per page: </span>
          <select
            value={per_page}
            onChange={(e) => setPer_page(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </Grid>
        <Pagination
          sx={{
            width: "max-content",
          }}
          color="primary"
          variant="text"
          onChange={(_, newValue) => setPage(newValue)}
          count={10}
          page={page}
          shape="rounded"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </div>
  );
};

export default VendorTermsAndConditionTableContent;
