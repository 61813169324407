import apiClient from "../../../utilities/apiClient";

export const addVendorsTerms = (payload) => {
  return apiClient.post(`${apiClient.Urls.addVendorTerms}`, payload, true);
};

export const VendorsTermsList = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.addVendorTerms}?page=${payload?.page}&per_page=${
      payload?.per_page
    }&search=${payload?.search || ""}`,
    "",
    true
  );
};
