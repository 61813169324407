import React, { useEffect } from "react";
import {
  Grid,
  Button,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextField,
  // FormControl,
  // Select,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { OfficerAdminTableContent } from "./components/officerAdminTableContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { VendorCardLabel } from "../vendorRegistration/components/StepMain";
import { Super } from "../vendorRegistration/components/steps/cardTrial";
import { useDispatch, useSelector } from "react-redux";
import { companyListForOfficer, invitationLink } from "./redux/sendLinkSlice";
import { useForm } from "react-hook-form";
import { check_workflow_step_thunk } from "../workflows/redux/userWorkflowsSlice";
// import { history } from "../../app/history";
import GlobalAutocomplete from "../../common/sharedComponents/AutoComplate";
import { useLazyGetCategorySAPwiseQuery } from "../../utilities/redux-api/vendor_category/api";
import LocalStorage from "../../utilities/localStorage";

const OfficerTable = () => {
  const { loading, companyList } = useSelector(
    (state) => state.sendInvitationLink
  );
  const { stepDefined } = useSelector((state) => state.userWorkflows);
  const clientId = LocalStorage.getItem("client")?.id;
  const dispatch = useDispatch();
  const checkWorkflowStep = (wf_name) => {
    dispatch(check_workflow_step_thunk(wf_name));
  };
  const [trigger, { data }] = useLazyGetCategorySAPwiseQuery();
  // const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [clicked, setClicked] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    // resetField,
  } = useForm();

  const onCompanySelect = (data) => {
    trigger(data?.key);
    setValue("category", null);
  };

  const handleSave = (data) => {
    const payload = {
      email: data?.email,
      cid: data?.company?.key,
      vcm_id: data?.category?.key,
    };
    dispatch(invitationLink(payload)).then((response) => {
      reset();
      if (response?.payload?.success) {
        setOpen(false);
      } else {
        reset();
        setOpen(false);
      }
    });
  };
  useEffect(() => {
    dispatch(companyListForOfficer(""));
  }, [dispatch]);
  // useEffect(() => {
  //   if (open) {
  //     checkWorkflowStep();
  //   }
  //   // eslint-disable-next-line
  // }, [open]);
  useEffect(() => {
    if (!stepDefined) {
      setOpen(false);
    }
  }, [stepDefined, open]);
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Vendors
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
            xs={6}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>
            {/* dropdown */}
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "2rem",
                      height: "2.5rem",
                      width: "4.3rem",
                    }}
                    {...bindTrigger(popupState)}
                  >
                    +
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <Link
                        to="/app/add-vendor"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Vendor Registration
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={(popupState.close, () => setOpen(true))}>
                      Invite Vendor
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <OfficerAdminTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
        />
      </StepperContentHolder>

      {/* add file dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Send Link
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>
          <br />
          <DialogContentText>
            <form onSubmit={handleSubmit(handleSave)}>
              <Grid container spacing={1}>
                <Grid
                  item
                  sx={{
                    width: "100%",
                  }}
                >
                  <VendorCardLabel>
                    Vendor Email<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    style={{ width: "100%" }}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter Vendor Email"
                    variant="standard"
                    name="email"
                    {...register("email", {
                      required: "please enter email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "please enter valid email",
                      },
                    })}
                    error={Boolean(errors?.email)}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "100%",
                  }}
                >
                  <GlobalAutocomplete
                    label={"Select Company"}
                    name={"company"}
                    placeholder={"Select a Company"}
                    options={companyList.map((company, index) => {
                      return {
                        key: company.id,
                        value: company?.company_name,
                      };
                    })}
                    control={control}
                    rules={{ required: `Company is required` }}
                    onSelect={onCompanySelect}
                  />
                </Grid>
                {clientId === 2 && (
                  <Grid
                    item
                    sx={{
                      width: "100%",
                    }}
                  >
                    <GlobalAutocomplete
                      label={"Select Vendor Category"}
                      name={"category"}
                      placeholder={"Select a Vendor Category"}
                      options={
                        data?.data?.map((el, index) => {
                          return {
                            key: el.id,
                            value: `${el.type} (${el?.type_code})`,
                          };
                        }) ?? []
                      }
                      control={control}
                      rules={{ required: `Vendor Category is required` }}
                      onSelect={() => {}}
                    />
                  </Grid>
                )}
              </Grid>
              <DialogActions style={{ alignItems: "right" }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  Send Link
                </LoadingButton>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OfficerTable;
