import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addVendorsTerms,
  VendorsTermsList,
} from "./vendorTermsAndConditionApi";
import { message } from "antd";

const initialState = {
  addVendorTerms: {},
  getVendorTerms: [],
};

const actions = {
  ADDVENDORTERM: "addVendorTerms/ADDVENDORTERM",
  GETVENDORTERM: "getVendorTerms/GETVENDORTERM",
};

export const getAddVendorTerms = createAsyncThunk(
  actions.ADDVENDORTERM,
  async (payload) => {
    const response = await addVendorsTerms(payload);
    return response;
  }
);

export const getAddVendorTermsList = createAsyncThunk(
  actions.GETVENDORTERM,
  async (payload) => {
    const response = await VendorsTermsList(payload);
    return response;
  }
);

export const vendorTermsSlice = createSlice({
  name: "vendorTermsCondition",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAddVendorTerms.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAddVendorTerms.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.addVendorTerms = data;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getAddVendorTerms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAddVendorTermsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAddVendorTermsList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.getVendorTerms = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getAddVendorTermsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const vendorTermsAction = vendorTermsSlice.actions;
export default vendorTermsSlice.reducer;
