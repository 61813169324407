import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  vendor_reg,
  goodsService,
  categories,
  contact_add,
  validate_gst,
  add_business_loc,
  view_vendor_details,
  vendor_reg_update,
  approve_vendor,
  update_business_loc,
  itemcategories,
  upload_gst_doc,
  getItems,
  banksApi,
  validatePan,
  createuser,
  fetchLatestVendorTermsAndCondtionsDoc,
} from "./vendorregAPI";
// import { history } from "../../../app/history";

const initialState = {
  loading: false,
  token: "",
  user: null,
  first_name: "",
  last_name: "",
  categories: [],
  subcategories: [],
  contacts: [],
  gstlocations: [],
  vendor_details: [],
  itemcategories: [],
  items: [],
  getBanksDetails: [],
  vendorTermsAndConditions: {},
  createdUser: {
    id: "0",
  },
};

const actions = {
  VENDORREGISTRATION: "vendorRegistration/VENDORREGISTRATION",
  HSNCATEGORIES: "hsnCategories/HSNCATEGORIES",
  HSNVALUES: "hsnValues/HSNVALUES",
  ADDCONTACT: "addContact/ADDCONTACT",
  VALIDATEGST: "GSTvalidate/VALIDATEGST",
  BUSINESSLOCATIONS: "bussinesslocations/BUSINESSLOCATIONS",
  VIEWVENDORDETAILS: "getVendorDetails/VIEWVENDORDETAILS",
  VENDORREGISTRATIONUPDATE: "vendorRegistrationUpdate/VENDORREGISTRATIONUPDATE",
  APPROVEVENDOR: "approveVendor/APPROVEVENDOR",
  BUSINESSLOCATIONSUPDATE: "bussinesslocationsupdate/BUSINESSLOCATIONSUPDATE",
  ITEMVALUES: "itemValues/ITEMVALUES",
  ITEM: "item/ITEM",
  GSTDOCUMENT: "GSTdocument/GSTDOCUMENT",
  GETBANKSDETAILS: "getBanksDetails/GETBANKSDETAILS",
  CREATEUSER: "createUser/CREATEUSER",
  VALIDATEPAN: "validatePAN/VALIDATEPAN",
  VENDORTERMSANDCONDITIONS: "vendorTermsAndConditions/VENDORTERMSANDCONDITIONS",
};

export const validatePAN = createAsyncThunk(
  actions.VALIDATEPAN,
  async (payload) => {
    const response = await validatePan(payload);
    return response;
  }
);

export const getVendorTermsAndConditions = createAsyncThunk(
  actions.VENDORTERMSANDCONDITIONS,
  async (payload) => {
    const response = await fetchLatestVendorTermsAndCondtionsDoc(payload);
    return response;
  }
);

export const createUser = createAsyncThunk(
  actions.CREATEUSER,
  async (payload) => {
    const response = await createuser(payload);
    return response;
  }
);

export const vendorRegistration = createAsyncThunk(
  actions.VENDORREGISTRATION,
  async (payload) => {
    const response = await vendor_reg(payload);
    return response;
  }
);
export const hsnCategories = createAsyncThunk(
  actions.HSNCATEGORIES,
  async (payload) => {
    const response = await goodsService(payload);
    return response;
  }
);

export const hsnValues = createAsyncThunk(
  actions.HSNVALUES,
  async (payload) => {
    const response = await categories(payload);
    return response;
  }
);

export const addContact = createAsyncThunk(
  actions.ADDCONTACT,
  async (payload) => {
    const response = await contact_add(payload);
    return response;
  }
);

export const GSTvalidate = createAsyncThunk(
  actions.GSTvalidate,
  async (payload) => {
    const response = await validate_gst(payload);
    return response;
  }
);

export const bussinesslocations = createAsyncThunk(
  actions.BUSINESSLOCATIONS,
  async (payload) => {
    const response = await add_business_loc(payload);
    return response;
  }
);

export const bussinesslocationsupdate = createAsyncThunk(
  actions.BUSINESSLOCATIONSUPDATE,
  async (payload) => {
    const response = await update_business_loc(payload);
    return response;
  }
);

export const getVendorDetails = createAsyncThunk(
  actions.VIEWVENDORDETAILS,
  async (payload) => {
    const response = await view_vendor_details(payload);
    return response;
  }
);

export const approveVendor = createAsyncThunk(
  actions.APPROVEVENDOR,
  async (payload) => {
    const response = await approve_vendor(payload);
    return response;
  }
);

export const vendorRegistrationUpdate = createAsyncThunk(
  actions.VENDORREGISTRATIONUPDATE,
  async (payload) => {
    const response = await vendor_reg_update(payload);
    return response;
  }
);

export const itemValues = createAsyncThunk(
  actions.ITEMVALUES,
  async (payload) => {
    const response = await itemcategories(payload);
    return response;
  }
);
export const getMasterItems = createAsyncThunk(
  actions.ITEM,
  async (payload) => {
    const response = await getItems(payload);
    return response;
  }
);

export const GSTdocument = createAsyncThunk(
  actions.GSTDOCUMENT,
  async (payload) => {
    const response = await upload_gst_doc(payload);
    return response;
  }
);
export const getBanks = createAsyncThunk(
  actions.GETBANKSDETAILS,
  async (vendor_id) => {
    const response = await banksApi(vendor_id);
    return response;
  }
);

export const vendorRegistrationSlice = createSlice({
  name: "vendorRegistration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendorTermsAndConditions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorTermsAndConditions.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          state.vendorTermsAndConditions = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorTermsAndConditions.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(vendorRegistration.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorRegistration.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          /* message.error(msg); */
        }
      })
      .addCase(vendorRegistration.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(hsnCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(hsnCategories.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.categories = data;
        state.subcategories = [];
      })
      .addCase(hsnCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getMasterItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMasterItems.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.Items = data;
      })
      .addCase(getMasterItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(hsnValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(hsnValues.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.subcategories = data;
      })
      .addCase(hsnValues.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(itemValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(itemValues.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.itemcategories = data;
      })
      .addCase(itemValues.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(addContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(addContact.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
          state.contacts = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(addContact.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(GSTvalidate.pending, (state) => {
        state.loading = true;
      })
      .addCase(GSTvalidate.fulfilled, (state, action) => {
        const { success, message: msg, gst_api_result } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
          state.gstlocations = gst_api_result ? gst_api_result.data.adadr : [];
        } else {
          message.error(msg);
        }
      })
      .addCase(GSTvalidate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(bussinesslocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(bussinesslocations.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          /*  history.push("/app"); */
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(bussinesslocations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendor_details = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveVendor.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveVendor.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(bussinesslocationsupdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(bussinesslocationsupdate.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          /*  history.push("/app"); */
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(bussinesslocationsupdate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(GSTdocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(GSTdocument.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(GSTdocument.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        const { data, cuurency } = action.payload;
        state.loading = false;
        state.getBanksDetails = data;
        state.countrycurrency = cuurency;
        // if (success) {
        //   message.success(msg);
        // } else {
        //   message.error(msg);
        // }
      })
      .addCase(getBanks.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(validatePAN.pending, (state) => {
        state.loading = true;
      })
      .addCase(validatePAN.fulfilled, (state, action) => {
        const { data, success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          state.legal_name = data;
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(validatePAN.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const { data, success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          state.createdUser = { id: data.id };
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
  },
});
export const vendorRegistrationActions = vendorRegistrationSlice.actions;
export const { resetvendorRegistrationDetails } =
  vendorRegistrationSlice.actions;

export default vendorRegistrationSlice.reducer;
