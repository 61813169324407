import React, { useState } from "react";

import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { Button, Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import { Link } from "react-router-dom";
import VendorTermsAndConditionTableContent from "./components/vendorTermsAndConditionTableContent";

const VendorTermsAndConditionTable = () => {
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Vendor Terms & Conditions
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>
            <Link to="/app/add-vendorTerms">
              <Button
                variant="contained"
                sx={{
                  fontSize: "2rem",
                  height: "2.5rem",
                  width: "4.3rem",
                }}
                size="large"
              >
                +
              </Button>
            </Link>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <VendorTermsAndConditionTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
        />
      </StepperContentHolder>
    </div>
  );
};

export default VendorTermsAndConditionTable;
