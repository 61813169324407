import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_vendors,
  get_vendor_emails,
  prs,
  get_prs,
  view_AdminPrDetails,
  view_AdminApprovedItemsPrDetails,
  view_AdminItemDetails,
  add_excel_data,
  get_materials,
  approve_pr,
  push_pr_to_pg,
  approve_item,
  PR_preview,
  get_all_prs,
  copy_pr,
} from "./adminPrsAPI";

const initialState = {
  loading: false,
  vendors: [],
  materials: [],
  vendoremails: [],
  prsList: [],
  prsListPaginations: {},
  adminPrDetails: [],
  adminApprovedItemsPrDetails: [],
  adminItemDetails: [],
  prDownload: {},
};
const actions = {
  GETVENDORS: "getVendors/GETVENDORS",
  GETMATERIALS: "getMaterials/GETMATERIALS",
  GETVENDOREMAILS: "getVendorsemails/GETVENDOREMAILS",
  CREATEPR: "createPr/CREATEPR",
  GETPRS: "getPrs/GETPRS",
  ADDEXCEL: "addExcel/ADDEXCEL",
  APPROVEPR: "approvePr/APPROVEPR",
  APPROVEITEM: "approveItem/APPROVEITEM",
  VIEWADMINPRDETAILS: "getAdminPrDetails/VIEWADMINPRDETAILS",
  VIEWADMINAPPROVEDITEMSPRDETAILS:
    "getAdminApprovedItemsPrDetails/VIEWADMINAPPROVEDITEMSPRDETAILS",
  PUSHPRTOPG: "pushpushPRToPg/PUSHPRTOPG",
  VIEWADMINITEMDETAILS: "getAdminItemDetails/VIEWADMINITEMDETAILS",
  PRDOWNLOAD: "prDownlaod/PRDOWNLOAD",
  GETALLPRS: "getAllPrs/GETALLPRS",
  COPYPR: "copyPr/COPYPR",
};

export const addExcelData = createAsyncThunk(
  actions.ADDEXCEL,
  async (payload) => {
    const response = await add_excel_data(payload);
    return response;
  }
);
export const getVendors = createAsyncThunk(
  actions.GETVENDORS,
  async (payload) => {
    const response = await get_vendors(payload);
    return response;
  }
);

export const approvePr = createAsyncThunk(
  actions.APPROVEPR,
  async (payload) => {
    const response = await approve_pr(payload);
    return response;
  }
);

export const approveItem = createAsyncThunk(
  actions.APPROVEITEM,
  async (payload) => {
    const response = await approve_item(payload);
    return response;
  }
);

export const pushPRToPg = createAsyncThunk(
  actions.PUSHPRTOPG,
  async (payload) => {
    const response = await push_pr_to_pg(payload);
    return response;
  }
);

export const getMaterials = createAsyncThunk(
  actions.GETMATERIALS,
  async (payload) => {
    const response = await get_materials(payload);
    return response;
  }
);

export const getVendorsemails = createAsyncThunk(
  actions.GETVENDOREMAILS,
  async (payload) => {
    const response = await get_vendor_emails(payload);
    return response;
  }
);

export const createPr = createAsyncThunk(actions.CREATEPR, async (payload) => {
  const response = await prs(payload);
  return response;
});

export const getAdminPrDetails = createAsyncThunk(
  actions.VIEWADMINPRDETAILS,
  async (payload) => {
    const response = await view_AdminPrDetails(payload);
    return response;
  }
);

export const getAdminApprovedItemsPrDetails = createAsyncThunk(
  actions.VIEWADMINAPPROVEDITEMSPRDETAILS,
  async (payload) => {
    const response = await view_AdminApprovedItemsPrDetails(payload);
    return response;
  }
);

export const getAdminItemDetails = createAsyncThunk(
  actions.VIEWADMINITEMDETAILS,
  async (payload) => {
    const response = await view_AdminItemDetails(payload);
    return response;
  }
);
export const prDownlaodThunk = createAsyncThunk(
  actions.PRDOWNLOAD,
  async (payload) => {
    const response = await PR_preview(payload);
    return response;
  }
);

export const getPrs = createAsyncThunk(actions.GETPRS, async (payload) => {
  const response = await get_prs(
    payload?.status,
    payload?.filter,
    payload?.page,
    payload?.per_page,
    payload?.search
  );
  return response;
});

export const getAllPrs = createAsyncThunk(
  actions.GETALLPRS,
  async (payload) => {
    const response = await get_all_prs();
    return response;
  }
);

export const copyPr = createAsyncThunk(actions.COPYPR, async (payload) => {
  const response = await copy_pr(payload);
  return response;
});

export const adminPrsSlice = createSlice({
  name: "adminPrs",
  initialState,
  reducers: {
    resetMatrialDetails: (state) => {
      state.materials = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendors = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getMaterials.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMaterials.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.materials = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approvePr.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvePr.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approvePr.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveItem.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveItem.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(pushPRToPg.pending, (state) => {
        state.loading = true;
      })
      .addCase(pushPRToPg.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.loading = false;
        if (status === 200) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(pushPRToPg.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorsemails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorsemails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendoremails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorsemails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAdminPrDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminPrDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminPrDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminPrDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAdminApprovedItemsPrDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminApprovedItemsPrDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminApprovedItemsPrDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminApprovedItemsPrDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAdminItemDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminItemDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminItemDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminItemDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createPr.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPr.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPr.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(addExcelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addExcelData.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addExcelData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPrs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrs.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        state.prsList = data.data;
        state.prsListPaginations = data.pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(prDownlaodThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(prDownlaodThunk.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        state.prDownload = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(prDownlaodThunk.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPrs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPrs.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        state.allPrs = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllPrs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(copyPr.pending, (state) => {
        state.loading = true;
      })
      .addCase(copyPr.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.newPrDetails = data;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(copyPr.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminPrsActions = adminPrsSlice.actions;
export const { resetMatrialDetails, resetadminPrsDetails } =
  adminPrsSlice.actions;

export const resetMatrials = () => (dispatch) => {
  dispatch(resetMatrialDetails());
};
export default adminPrsSlice.reducer;
