import {
  Grid,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
  Tab,
  Box,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormHelperText,
  Typography,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminPrsTableContent from "./components/adminPrsTableContent";
import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";

// dropdown dependance

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPrCreationStatus } from "../workflows/redux/userWorkflowsSlice";
import {
  CustomBox,
  CustomTab,
} from "../adminTable/components/adminTableContent";
import SwipeableViews from "react-swipeable-views";
import { getPrs, getAllPrs } from "./redux/adminPrsSlice";
import { useForm } from "react-hook-form";
import { history } from "../../app/history";
import moment from "moment";
import { capitalizeString } from "../../utilities/helper";

function AdminPrsTable() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { prCreationStatus } = useSelector((state) => state.userWorkflows);
  const { allPrs } = useSelector((state) => state.adminPrs);
  const [filter, setFilter] = useState("All");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [search, setSearch] = React.useState(null);
  const [timeoutId, setTimeoutId] = React.useState(null);

  const [openCopyPR, setOpenCopyPR] = React.useState(false);
  const [prNumber, setPrNumber] = useState("");
  const [isPRNumberSelected, setIsPRNumberSelected] = useState(false);
  const [sourcePrDetails, setSourcePrDetails] = useState({});

  const {
    register,
    formState: { errors },
  } = useForm();

  const handleCopyPR = () => {
    // dispatch(copyPr(sourcePrDetails)).then((response) => {
    // if (response.payload.success) {
    // dispatch(getCompaniesdetails(project_id));
    // dispatch(getAdminPrDetails(sourcePrDetails?.id)).then((res) => {
    // if (res.payload.success) {
    history.push(`/app/copy-pr/${sourcePrDetails?.id}`);
    // }
    // });
    // }
    setOpenCopyPR(false);
    setPrNumber("");
    setIsPRNumberSelected(false);
    // });
  };

  const handleCopyPrNumberChange = (e) => {
    const local_pr_number = e.target.value;
    setPrNumber(local_pr_number);
    setIsPRNumberSelected(true);

    const newSourcePrDetails = allPrs?.find(
      (el) => el.pr_number === local_pr_number
    );
    if (newSourcePrDetails) {
      setSourcePrDetails(newSourcePrDetails);
    }
  };

  const handleCopyPRClose = () => {
    setOpenCopyPR(false);
    setPrNumber("");
    setIsPRNumberSelected(false);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CustomBox
            sx={{
              pt: 3,
              backgroundColor: "#f5f7f9",
            }}
          >
            <div>{children}</div>
          </CustomBox>
        )}
      </div>
    );
  }
  const getData = () => {
    dispatch(getPrCreationStatus());
  };

  const prs = () => {
    let payload = { search, filter, page, per_page };

    if (value === 0) {
      payload = {
        ...payload,
        status: "pending",
      };
    }
    if (value === 1) {
      payload = {
        ...payload,
        status: "completed",
      };
    }
    if (value === 2) {
      payload = {
        ...payload,
        status: "rejected",
      };
    }

    dispatch(getPrs(payload));
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    prs();
    // eslint-disable-next-line
  }, [filter, value, page, per_page]);

  useEffect(() => {
    // if (search.length >= 4) {
    //   prs(search);
    // }
    // Clear the existing timeout if inputValue changes
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    const id = setTimeout(() => {
      if (search) {
        prs(search);
      }
    }, 2000);
    // Save the timeout ID
    setTimeoutId(id);
    // Clear timeout on component unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line
  }, [search]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={3} flex justifyContent={"space-between"}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={4}>
            Purchase Requisitions
          </Grid>

          <Grid item xs={5}>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "right",
              }}
            >
              <FormControl variant="standard" size="small">
                <InputLabel>Filter</InputLabel>
                <Select
                  value={filter}
                  onChange={handleFilterChange}
                  label="Filter"
                  // size="small"
                  sx={{ width: "75px" }}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="my">My PRs</MenuItem>
                  <MenuItem value="assigned">Assigned PRs</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="standard"
                size="small"
                sx={{ fullWidth: "250px" }}
                label="Search by PR Number"
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* <Button
                variant="contained"
                size="small"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button> */}

              {prCreationStatus?.is_granted && (
                <>
                  {/* dropdown */}
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "2rem",
                            height: "3rem",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          +
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            <Link
                              to="/app/prs"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              Create PR
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenCopyPR(true);
                              dispatch(getAllPrs());
                            }}
                          >
                            Copy PR
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
          <CustomTab
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Pending</Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Approved/Pushed</Grid>
                </Grid>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Rejected</Grid>
                </Grid>
              }
              {...a11yProps(2)}
            />
          </CustomTab>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </StepperContentHolder>

      <Dialog
        open={openCopyPR}
        onClose={handleCopyPRClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Copy Purchase Requisition"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select a purchase requisition to fetch its details
          </DialogContentText>
          <FormControl
            fullWidthg
            variant="outlined"
            margin="normal"
            error={!!errors.prNumber}
          >
            <Select
              labelId="dropdown-label"
              value={prNumber}
              {...register("prNumber", {
                // required: "Please select PR Number",
                value: prNumber,
              })}
              onChange={handleCopyPrNumberChange}
            >
              <MenuItem value="" disabled>
                Select PR Number
              </MenuItem>
              {allPrs?.length > 0 &&
                allPrs?.map((el) => (
                  <MenuItem key={el.id} value={el.pr_number}>
                    {el.pr_number}
                  </MenuItem>
                ))}
            </Select>
            {errors.prNumber && (
              <FormHelperText error>{errors.prNumber.message}</FormHelperText>
            )}
          </FormControl>
          <br />
          {isPRNumberSelected && (
            <DialogContentText id="alert-dialog-description">
              Source : Purchase Requisition Details
              <Grid container spacing={2} sx={{ marginTop: "1px" }}>
                <Grid item md={4}>
                  <Typography className="label" sx={{ color: "black" }}>
                    PR Number
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className="label">
                    {sourcePrDetails?.pr_number
                      ? sourcePrDetails.pr_number
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography className="label" sx={{ color: "black" }}>
                    Desired Delivery Date
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className="label">
                    {sourcePrDetails?.desired_delivery_date
                      ? moment(sourcePrDetails.desired_delivery_date).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography className="label" sx={{ color: "black" }}>
                    Urgency Level
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className="label">
                    {sourcePrDetails?.urgency_level
                      ? capitalizeString(sourcePrDetails.urgency_level)
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCopyPRClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            Close
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            autoFocus
            size="small"
            disabled={!isPRNumberSelected}
            onClick={handleCopyPR}
          >
            Make a Copy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminPrsTable;
